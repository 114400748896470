/**
 * Enum containing media sizes
 */
export enum Media {

  UNDEFINED,
  SMALL,
  MEDIUM,
  LARGE
}

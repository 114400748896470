<ng-container class="about">
  <ng-container *transloco="let t">
      <mat-toolbar>
        <div class="content flexcontainer">
          <button mat-icon-button class="toolbar-menu-item" aria-label="back" (click)="onBackClicked()">
            <mat-icon aria-hidden="false" aria-label="arrow left" fontIcon="arrow_back"></mat-icon>
          </button>
          <div class="flexbox"></div>
          <span class="about-toolbar-title">{{ t("app-title") }}</span>
          <div class="flexbox"></div>
          
        </div>
      </mat-toolbar>
    </ng-container>

  <div class="about-content">

      <img about-image class="about-icon lazyload" src="../../../assets/icons-maskable/ally_logo.png" alt="event image">


      <div class="about-title">
      <h1>About Us</h1>
      <div class="subtitle">
          Wir sind Karo, Jieshan, Flo und Lina,<br> die GründerInnen von AllyUp
      </div>

      </div>

      <div class="about-detailed-information">
      <p>
          AllyUp zeigt dir intersektional-feministische Veranstaltungen in deiner Stadt (Berlin) in gebündelter Form an. So findest du leichter, was dich interessiert und behältst den Überblick.
          Mit der App wollen wir feministische/intersektional-denkende Communities stärken und ihren Anliegen mehr Gehör verschaffen.        
      </p>
      <p>
          Feminismus, wie wir ihn leben und verstehen, ist intersektional, kapitalismuskritisch, anti-rassistisch und LGBTQIA-unterstützend. Außerdem ist uns Klimagerechtigkeit wichtig. Wir verstehen uns bezüglich dieser Themen als Verbündete (Allies) und möchten mehr Menschen ermutigen, sich als Allies  zusammenzutun. Also Ally Up!        
      </p>
      <p>
          Bei Rückfragen, Feedback oder Anregungen, wende dich gern an uns.
      </p>
      </div>
      <div class="impressum">
        <div class="footer">
        
          <div class = footer-title>
            <p><strong>Impressum</strong></p>
          </div>
          <p>Anbieter:<br />AllyUp<br />c/o Block Services<br />Stuttgarter Str. 106<br/>70736 Fellbach</p>
          <p>Kontakt:<br />E-Mail: allyup.apps@gmail.com<br />Website: ally-up.de</p>
        </div>
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Informationen zu externen Links
            </mat-panel-title>
    
          </mat-expansion-panel-header>
          <p>
            Die Schaltfläche "Veranstaltungsdetails ansehen" bei jeder angezeigten Veranstaltung führt zur externen Seite des Anbieters der Veranstaltung. 
            Bei Links handelt es sich stets um dynamische Verweisungen. Wir haben die Quellen beim Einbinden geprüft, können sie jedoch nicht ständig auf Veränderungen untersuchen.
            Daher übernehmen wir keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. 
          </p>
        </mat-expansion-panel>
    
      </div>
  </div>
  
</ng-container>